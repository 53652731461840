import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import Link from "../components/HelperComponents/TransitionLink"

import SEO from "../components/seo"
import ContactFooter from "../components/ContactForm/ContactFooter"

import mainStyle, {
  sectionStyle,
  mainTextStyle,
  headerStyle,
} from "../components/styles/mainStyle"

const portfolioPage = ({ data }) => {
  const {
    titel,
    afbeelding,
    uitgebreid,
    url,
    meta_description,
  } = data.prismicPortfolioItem.data
  const {
    orange,
    main_color_two,
    main_color_three,
  } = data.prismicBasicInfo.data

  return (
    <>
      <SEO title={titel.text} description={meta_description.text} />

      <main
        className="borderradius-div-bottom"
        style={{ ...mainStyle, backgroundColor: main_color_two }}
      >
        <section style={sectionStyle}>
          <h1 className="resp-header" style={headerStyle}>
            {titel.text}
          </h1>
          <Img
            fluid={afbeelding.localFile.childImageSharp.fluid}
            className="borderradius-portfolio-img"
            style={{ margin: "0 25px 50px" }}
          />

          <section style={{ textAlign: "center", marginBottom: 25 }}>
            <a
              className="hover-orange"
              href={url.url}
              target="_blank"
              rel="noopener noreferrer"
            >
              {url.url}
            </a>
          </section>

          <section
            id="uitgebreide-text"
            dangerouslySetInnerHTML={{ __html: uitgebreid.html }}
            style={mainTextStyle}
          />

          <div style={{ textAlign: "center" }}>
            <Link
              to="/portfolio/"
              linkStyle={{
                display: "inline-block",
                color: main_color_three,
                textDecoration: "none",
                backgroundColor: orange,
                padding: 15,
                margin: "25px 0 75px",
                fontWeight: "bold",
                borderRadius: "5px 25px 5px 5px",
              }}
            >
              terug naar portfolio
            </Link>
          </div>
        </section>
      </main>

      <ContactFooter />
    </>
  )
}

export default portfolioPage

export const portfolioQuery = graphql`
  query portfolioBySlug($uid: String!) {
    prismicPortfolioItem(uid: { eq: $uid }) {
      uid
      data {
        titel {
          text
        }
        meta_description {
          text
        }
        uitgebreid {
          html
        }
        url {
          url
        }
        afbeelding {
          localFile {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
    prismicBasicInfo {
      data {
        orange
        main_color_two
        main_color_three
      }
    }
  }
`
